import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1823555e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid m-0 justify-content-center" }
const _hoisted_2 = { class: "flex col-12 justify-content-end align-items-center my-3" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Footer = _resolveComponent("Footer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        label: "New Deal Sheet",
        icon: "pi pi-plus",
        onClick: _ctx.handleNewDealSheet
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Card, null, {
        content: _withCtx(() => [
          _createVNode(_component_LoadingSpinner, { loading: _ctx.isLoadingDealSheet }, null, 8, ["loading"]),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isLoadingDealSheet)
              ? (_openBlock(), _createBlock(_component_DataTable, {
                  key: 0,
                  value: _ctx.dealsheet,
                  paginator: true,
                  rows: 10,
                  rowsPerPageOptions: [5, 10, 20],
                  onRowClick: _ctx.rowClick
                }, {
                  empty: _withCtx(() => [
                    _createTextVNode(" No records found ")
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "id",
                      header: "Dealsheet ID",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      sortable: true
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "p-column-filter",
                          placeholder: "Customer ID"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "agreement_start_date",
                      header: "Agreement Start Date",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      style: {"text-align":"right"}
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "p-column-filter",
                          placeholder: "Agreement Start Date"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "total_investment",
                      header: "Total Investment",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      style: {"text-align":"right"},
                      sortable: true
                    }, {
                      body: _withCtx(({ data }) => [
                        _createTextVNode(_toDisplayString(_ctx.formatCurrency(data.total_investment)), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "submitted_by",
                      header: "Submitted By",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "p-column-filter",
                          placeholder: "Submitted By"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "submitted_by_date",
                      header: "Submitted By Date",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      style: {"text-align":"right"}
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "p-column-filter",
                          placeholder: "Submitted By Date"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "approved_by",
                      header: "Approved By",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "p-column-filter",
                          placeholder: "Approved By"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "approved_by_date",
                      header: "Approved By Date",
                      showAddButton: false,
                      showFilterMatchModes: false,
                      showFilterOperator: false,
                      style: {"text-align":"right"}
                    }, {
                      filter: _withCtx(() => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          class: "p-column-filter",
                          placeholder: "Approved By Date"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, null, {
                      body: _withCtx(({ data }) => [
                        _withDirectives(_createVNode(_component_Button, {
                          class: "p-button-sm mx-1",
                          icon: _ctx.dealIcon(data.id),
                          onClick: ($event: any) => (_ctx.fetchPDFInvoice(data.id))
                        }, null, 8, ["icon", "onClick"]), [
                          [
                            _directive_tooltip,
                            `Download PDF ${data.id}`,
                            void 0,
                            { top: true }
                          ]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value", "onRowClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_Footer)
    ])
  ]))
}