
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Footer from "@/components/Footer.vue";
import Card from "primevue/card";
import Utils from "@/utility/utils";
import CustomDataService from "@/services/CustomDataService";
const customDataService = new CustomDataService();

export default defineComponent({
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    LoadingSpinner,
    Footer,
    Card,
  },
  mounted() {
    this.getDealSheetData();
  },
  data() {
    return {
      dealsheet: [] as any,
      isLoadingDealSheet: false,
      loadingPDFDeals: [] as Array<any>,
      dealsView: [] as Array<{ id: any; pdf: string }>,
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getCustomer: "customerInquiry/getCustomer",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
    }),
    getDealsheetIdsString(): string {
      return this.getCustomer(this.getCurrentCustTabIndex).dealsheet_ids?.join(
        " ",
      );
    },
  },
  watch: {
    getCurrentCustTabIndex(newVal, oldVal) {
      this.getDealSheetData();
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    updatePDFLoadingState(dealId: string | number) {
      const index = this.loadingPDFDeals.indexOf(dealId, 0);
      if (index > -1) this.loadingPDFDeals.splice(index, 1);
    },
    dealIcon(dealId: number) {
      let downloaded = this.dealsView.find((deal) => deal.id === dealId);
      let downloading = this.loadingPDFDeals.includes(dealId);
      if (downloading) {
        return "pi pi-spin pi-spinner";
      } else if (downloaded) {
        return "pi pi-file-pdf";
      } else {
        return "pi pi-download";
      }
    },
    fetchPDFInvoice(dealId: any) {
      const pdf = this.dealsView?.find((deal: any) => deal.id === dealId);
      if (pdf !== undefined) {
        window.open(pdf.pdf, "_blank");
      } else {
        this.loadingPDFDeals?.push(dealId);
        customDataService
          .getDownloadPdf({
            filename: "DEALSHEET",
            requestSource: "test",
            id: dealId,
            pdfFormName: "Dealsheet",
          })
          .then((response) => {
            const bufferArray = Utils.base64ToArrayBuffer(response);
            const blobStore = new Blob([bufferArray], {
              type: "application/pdf",
            });
            const data = window.URL.createObjectURL(blobStore);
            this.dealsView?.push({ id: dealId, pdf: data });
            this.updatePDFLoadingState(dealId);
            window.open(data, "_blank");
          })
          .catch(() => {
            this.updatePDFLoadingState(dealId);
          });
      }
    },
    formatCurrency: (value: string) => {
      if (value) {
        return parseInt(value, 10).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      } else {
        return "";
      }
    },
    async getDealSheetData() {
      try {
        this.isLoadingDealSheet = true;
        await customDataService
          .getdata({
            filename: "DEALSHEET",
            requestSource: "test",
            ids: this.getDealsheetIdsString,
          })
          .then((response) => {
            if (response) {
              this.dealsheet = response.dealsheet_items;
            }
          })
          .finally(() => {
            this.isLoadingDealSheet = false;
          });
      } catch (error) {
        this.addNotification({
          message: `Error getting Deal Sheet data: ${error}`,
          type: "error",
        });
        this.isLoadingDealSheet = false;
      }
    },
    rowClick(event: any) {
      if (event.data) {
        this.$router.push({
          name: "DealSheet",
          params: { cust_id: event.data.cust_id },
          query: { isEdit: true, dealSheetId: event.data.id },
        } as any);
      }
    },
    handleNewDealSheet() {
      this.$router.push({
        name: "DealSheet",
        params: { cust_id: this.getCurrentCustomer.cust_id },
        query: { isEdit: false },
      } as any);
    },
  },
});
